import { Router } from '@angular/router';
import { Injectable, NgModule } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, startWith, delay } from 'rxjs/operators';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SpotNotificationComponent } from './components/spot-notification/SpotNotification.component';
import { SampleService } from './sample.service';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';

@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {

    constructor(private notification: SpotNotificationComponent,
        private sampleService: SampleService,
        private router: Router) { }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {

        var exibirLoader = !req.url.includes("gerarMatricula");

        if (exibirLoader)
            this.sampleService.onNotify(true)

        return next.handle(req).pipe(startWith(null), delay(0), tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                this.sampleService.onNotify(false)

                if (req.method !== "GET" 
                    && (!req.url.includes("pesquisar") && !req.url.includes("login") && !req.url.includes("gerarMatricula")
                    && !req.url.includes("registrar")))
                    this.notification.showNotification("success");
            }
        },
            (err: any) => {
                if (err.status === 401) {
                    this.router.navigate(['login']);
                }

                if (err.status === 403) {
                    this.router.navigate(['login']);
                }

                if (err.status === 400) {
                    if (!req.url.includes("login"))
                        this.notification.showNotification("danger", err.error)
                }

                if (err.status === 500) {
                    this.notification.showNotification("danger", err.error)
                }

                this.sampleService.onNotify(false)
            }));
    }
}

@NgModule({
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpsRequestInterceptor,
            multi: true,
        },
    ],
})

export class Interceptor { }