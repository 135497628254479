import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from '../environments/environment';



@Injectable()
export class AppService
{
    private _apiUrl: string = environment.apiEndpoint;
    private _spotApiUrl: string = environment.spotEndpoint;

    private _headers;

    private sessaoValida: boolean = false;

    constructor(private http: HttpClient, private router: Router) { 
        this.validarSessao();

        if(!this.sessaoValida)
            return;

        this._headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', JSON.parse(localStorage.getItem('currentUser')).token);
    }

    Get(rota: string, params: HttpParams = null) : Observable<any>
    {
        this.validarSessao();

        if(!this.sessaoValida)
            return;

        this.obterHeader();

        return this.http.get(this._apiUrl + rota, {headers: this._headers, params });
    }

    GetSpot(rota: string, params: HttpParams = null) : Observable<any>
    {
        this.validarSessao();

        if(!this.sessaoValida)
            return;

        this.obterHeader();

        return this.http.get(this._spotApiUrl + rota, {headers: this._headers, params });
    }

    GetHtml(rota: string) : Observable<String>
    {
        this.validarSessao();

        if(!this.sessaoValida)
            return;

        this.obterHeader();

        return this.http.get(this._apiUrl + rota, {headers: this._headers, responseType: "text" })
    }

    Post(rota, corpo)
    {
        this.validarSessao();

        if(!this.sessaoValida)
            return;

        this.obterHeader();
        return this.http.post(this._apiUrl + rota, JSON.stringify(corpo), {headers: this._headers});
    }

    PostSpot(rota, corpo)
    {
        this.validarSessao();

        if(!this.sessaoValida)
            return;

        this.obterHeader();
        return this.http.post(this._spotApiUrl + rota, JSON.stringify(corpo), {headers: this._headers});
    }

    Put(rota, corpo)
    {
        this.validarSessao();

        if(!this.sessaoValida)
            return;

        this.obterHeader();
        return this.http.put(this._apiUrl + rota, JSON.stringify(corpo), {headers: this._headers});
    }

    PutParams(rota)
    {
        this.validarSessao();

        if(!this.sessaoValida)
            return;

        this.obterHeader();
        return this.http.put(this._apiUrl + rota, null, {headers: this._headers});
    }

    PutSituacaoAtivo(rota)
    {
        this.validarSessao();

        if(!this.sessaoValida)
            return;

        this.obterHeader();
        return this.http.put(this._apiUrl + rota, null, {headers: this._headers});
    }

    Delete(rota)
    {
        this.validarSessao();

        if(!this.sessaoValida)
            return;

        this.obterHeader();
        return this.http.delete(this._apiUrl + rota, {headers: this._headers});
    }

    private obterHeader()
    {
         this._headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', JSON.parse(localStorage.getItem('currentUser')).token);
    }

    private validarSessao(){
        if (!JSON.parse(localStorage.getItem('currentUser'))
            || (JSON.parse(localStorage.getItem('currentUser')).expires
                && new Date() > new Date(JSON.parse(localStorage.getItem('currentUser')).expires))) {
            this.router.navigate(['login']);
            return;
        }

        this.sessaoValida = true; 
    }

    PostFormData(rota, formData) {
        this.validarSessao();

        if(!this.sessaoValida)
            return;

        this.obterHeader();

        let c = new HttpHeaders()
        //.set('Content-Type', 'multipart/form-data')
        .set('Authorization', JSON.parse(localStorage.getItem('currentUser')).token);

        return this.http.post<any>(this._apiUrl + rota, formData, {headers: c});
      }
}