import { Component, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { SampleService } from './sample.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css', './login/util.css', './login/util.css', './login/main.css']
})
export class AppComponent implements AfterViewChecked {
    HTTPActivity: boolean;

    ngAfterViewChecked(): void {
        this.sampleService.notify.subscribe((result) => {
            if (result !== this.HTTPActivity){
                this.HTTPActivity = result;
                this.cdRef.detectChanges();
            }
        })
    }

    constructor(private sampleService: SampleService, private cdRef : ChangeDetectorRef) {
        
    }
}