import { Component, OnInit } from '@angular/core';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/dashboard', title: 'Dashboard', icon: '../assets/img/dashboard.svg', class: ''},
    { path: '/meuPerfil/', title: 'Meu Perfil', icon: '../assets/img/dashboard.svg', class: ''},
    { path: '/funcionarios', title: 'Funcionários', icon: '../assets/img/funcionarios.svg', class: ''},
    { path: '/filiais', title: 'Empresas', icon: '../assets/img/empresa.svg', class: ''},
    { path: '/escalas', title: 'Escalas', icon: '../assets/img/escala-dash.svg', class: ''},
    { path: '/abonos', title: 'Abonos', icon: '../assets/img/abono.svg', class: ''},
    { path: '/gestaoHorarios', title: 'Gestão Horários', icon: '../assets/img/gestao-horarios-dash.svg', class: ''},
    { path: '/relatorios', title: 'Relatórios', icon: '../assets/img/relatorio-dash.svg', class: ''},
    //{ path: '/ferias', title: 'Ferias',  icon:'content_paste', class: '' }
];

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
    menuItems: any[];

    perfilUsuario: number;
    usuarioLogadoId: number;

    constructor() { }

    ngOnInit() {
        this.perfilUsuario = JSON.parse(localStorage.getItem('currentUser')).role;

        const ROUTESUSUARIOINTERNO: RouteInfo[] = [
            { path: '/dashboard', title: 'Dashboard', icon: '../assets/img/dashboard.svg', class: ''},
            { path: '/meuPerfil/', title: 'Meu Perfil', icon: '../assets/img/dashboard.svg', class: ''}
        ];

        if (this.perfilUsuario == 1)
            this.menuItems = ROUTES.filter(menuItem => menuItem);
        else 
            this.menuItems = ROUTESUSUARIOINTERNO.filter(menuItem => menuItem);
    }

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };
}