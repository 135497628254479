import { Injectable } from '@angular/core';
import { AppService } from "app/App.service";

@Injectable()
export class SetorService
{
    constructor(private appService: AppService) { }

    ListarSetores()
    {
        return this.appService.Get('setor');
    }
}