import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppService } from "app/App.service";

@Injectable()
export class FuncionarioService
{
    constructor(private appService: AppService) { }

    ListarFuncionario(corpo) : Observable<any>
    {
        return this.appService.Post('funcionario/pesquisar', corpo);
    }

    ListarFuncionarioResumo() : Observable<any>
    {
        return this.appService.Get('funcionarios/listar');
    }

    ListarFuncionariosAniversariantes() : any
    {
        return this.appService.Get('funcionarios/aniversariantes');
    }

    ListarFuncionarioNotificacoes(funcionarioId) : Observable<any>
    {
        return this.appService.Get('funcionario/'+ funcionarioId + '/notificacoes');
    }

    ListarFuncionariosTrabalhando()
    {
        return this.appService.Get('funcionarios/trabalhando');
    }

    ObterFuncionario(funcionarioId)
    {
        return this.appService.Get('funcionario/' + funcionarioId + '?composicao=6');
    }

    ObterFuncionarioImagem(funcionarioId)
    {
        return this.appService.Get('funcionario/' + funcionarioId + '/download/perfil');
    }

    ObterFuncionarioComposicao(funcionarioId, composicao)
    {
        return this.appService.Get('funcionario/' + funcionarioId + '?composicao='+composicao);
    }

    IncluirFuncionario(corpo) : any
    {
        return this.appService.Post('funcionario', {funcionario: corpo})
    }

    AlterarFuncionario(funcionarioId, corpo) : any
    {
        return this.appService.Put('funcionario/' + funcionarioId, {funcionario: corpo})
    }

    Alterarusuario(funcionarioId, corpo)
    {
        return this.appService.Put('funcionario/'+funcionarioId+'/configuracoes', {funcionarioUsuario: corpo});
    }

    GerarMatricula(funcionarioId): any
    {
        return this.appService.Put('funcionario/'+funcionarioId+'/gerarMatricula', null);
    }

    ObterCard(funcionarioId)
    {
        return this.appService.Get('funcionario/' + funcionarioId + '/card');
    }

    inserirDataTemporario(funcionarioId, corpo) : any
    {
        return this.appService.Post('funcionario/' + funcionarioId + '/temporario', {FuncionarioTemporarioData: corpo})
    }

    removerDataTemporario(funcionarioId, dataId) : any
    {
        return this.appService.Delete('funcionario/' + funcionarioId + '/temporario/' + dataId);
    }

    AlterarImagem(funcionarioId, corpo) : any
    {
        return this.appService.PostFormData('funcionario/' + funcionarioId + '/upload/perfil/', corpo)
    }
}