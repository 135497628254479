import { Injectable } from '@angular/core';
import { AppService } from "app/App.service";
import { Observable } from 'rxjs';

@Injectable()
export class EscalaService
{
    constructor(private appService: AppService) { }

    inserirEscala(escala) : any
    {
        return this.appService.Post('escala', {escala: escala});
    }

    listarEscalas(): Observable<any>
    {
        return this.appService.Get('escalas');
    }

    obterEscala(escalaId) : any
    {
        return this.appService.Get('escala/'+escalaId);
    }

    alterarEscala(escala) : any
    {
        return this.appService.Put('escala/' + escala.id, {escala: escala});
    }

    obterVisualizacao(escalaId: number) : any {
        return this.appService.Get('escala/' + escalaId + '/visualizacao');
    }

    listarVisualizacao() : any {
        return this.appService.Get('escalas/visualizacao');
    }

    inserirDiaExcepcional(escalaId, escalaDiaExcepcional) : any {
        return this.appService.Post('escala/' + escalaId + '/diaExcepcional', {EscalaDiaExcepcional: escalaDiaExcepcional});
    }

    removerDiaExcepcional(escalaId, escalaDiaExcepcionalId) : any {
        return this.appService.Delete('escala/' + escalaId + '/diaExcepcional/' + escalaDiaExcepcionalId);
    }

    vincularFuncionarios(escalaId, funcionarioIds) : any {
        return this.appService
            .Put('escala/' + escalaId + '/funcionario/vincular', {FuncionariosIds: funcionarioIds})
            .subscribe();
    }
}