import { environment } from './../../environments/environment.prod';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable()
export class RegistroPontoService
{
    headers = new HttpHeaders()
        .set('Content-Type', 'application/json');

    private _apiUrlRegistroPonto: string = environment.apiEndpoint + '/ponto/registrar';
        
    constructor(private http: HttpClient) { }

    Registrar(corpo) : Observable<any>
    {
        return this.http.post(this._apiUrlRegistroPonto, JSON.stringify({Ponto:corpo}), {headers: this.headers});
    }
}