import { Injectable } from '@angular/core';
import { AppService } from "app/App.service";
import { Observable } from 'rxjs';
import { FuncionarioHorario, Horarios } from './FuncionarioHorario';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class GestaoHorariosService
{
    constructor(private appService: AppService) { }

    listarHorarios(funcionarioIds: Array<number>): Observable<Horarios>
    {
        let params = new HttpParams();
        
        funcionarioIds.forEach(p => { params = params.append('funcionarioIds', p.toString() );})

        return this.appService.Get('funcionario/previsao', params);
    }

    salvarHorarios(horarios: Horarios) {
        return this.appService.Post('funcionario/gestaohorarios', horarios);
    }
}
