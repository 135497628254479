import { Injectable } from '@angular/core';
import { AppService } from "app/App.service";
import { Abono } from './Abono';
import { Observable } from 'rxjs';

@Injectable()
export class AbonoService
{
    constructor(private appService: AppService) { }

    inserirAbono(abono: Abono){
        return this.appService.Post('abono', {abono: abono});
    }

    pesquisar(funcionarioIds, situacoesIds) : Observable<any>{
        return this.appService.Post('abonos/pesquisar', {funcionarioIds: funcionarioIds, SolicitacaoSituacoes: situacoesIds});
    }

    aprovarSolicitacaoAbono(abonoId: number, observacoes: string){
        return this.appService.Put('abono/' + abonoId + '/aprovar', { Observacoes: observacoes });
    }

    negarSolicitacaoAbono(abonoId: number, observacoes: string){
        return this.appService.Put('abono/' + abonoId + '/negar', { Observacoes: observacoes });
    }

    removerAbono(abonoId: number){
        return this.appService.Delete('abono/' + abonoId);
    }

    listarSolicitacoes(funcionarioId) : Observable<any>
    {
        return this.appService.Get('abonos/solicitacoes/funcionario/' + funcionarioId);
    }
}