import { Injectable } from '@angular/core';
import { AppService } from 'app/App.service';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class RegistroPontoFuncionarioService
{
    constructor(private appService: AppService) { }

    RegistrarPonto(){
        return this.appService.Post('ponto', null);
    }

    IncluirPonto(corpo){
        return this.appService.Post('ponto/novo', {ponto:corpo});
    }

    AlterarRegistro(pontoId, corpo){
        return this.appService.Put('ponto/' + pontoId + '/alterar', {ponto:corpo});
    }

    inativarRegistro(pontoId, corpo){
        return this.appService.Put('ponto/' + pontoId + '/inativar', corpo);
    }

    ListarRegistrosPonto(funcionarioId, dataInicial, dataFinal) {
        return this.appService.GetSpot('funcionarios/'+funcionarioId+'/folhaPontoMensal?Ano='+this.DataString(dataInicial)+'&Mes='+this.DataString(dataFinal));
    }

    DataString(data: Date){
        var day = data.getDate();
        var month = data.getMonth() + 1;
        var year = data.getFullYear();

        return year + "-" + month + "-" + day;
    }

    aprovarSolicitacaoPonto(pontoId: number){
        return this.appService.Post('ponto/' + pontoId + '/aprovarSolicitacao', null);
    }
    
    negarSolicitacaoPonto(respostaNegacao: any){
        return this.appService.Post('ponto/' + respostaNegacao.PontoId + '/negarSolicitacao', {RespostaNegacao: respostaNegacao});
    }

    listarSolicitacaoPonto() : any {
        return this.appService.Get('funcionarios/solicitacoes');
    }

    gerarRelatorio(funcionarioId, mes) : any {
        return this.appService.Get('funcionario/' + funcionarioId + '/relatorio/registrosPonto?Data='+mes);
    }

    gerarRelatorioMultiplosFuncionarios(funcionarioIds, mes) : any {

        let params = new HttpParams();
        funcionarioIds.forEach(p => { params = params.append('FuncionarioIds', p.toString() );})
        params = params.append('data', mes);

        return this.appService.Get('funcionario/relatorio/registrosPonto', params);
    }

    gerarRelatorioTermoUso(funcionarioIds) : any {

        let params = new HttpParams();
        funcionarioIds.forEach(p => { params = params.append('FuncionarioIds', p.toString() );})

        return this.appService.Get('funcionario/relatorio/termoAceitacaoUsoSpot', params);
    }
     
    inserirAbono(abono) : any {
        return this.appService.Post('abono/funcionario/' + abono.FuncionarioId, {Abono: abono});
    }

    aprovarSolicitacaoAbono(solicitacaoAbonoId, respostaAbono) : any {
        return this.appService.Put('abono/solicitacao/' + solicitacaoAbonoId+ '/aprovar/', {Observacoes: respostaAbono});
    }

    NegarSolicitacaoAbono(solicitacaoAbonoId, respostaAbono) : any {
        return this.appService.Put('abono/solicitacao/' + solicitacaoAbonoId+ '/negar/', {Observacoes: respostaAbono});
    }

    
    listarPontMes(funcionarioId, data) : any {
        const usingSplit = data.split('-');
        return this.appService.GetSpot('funcionarios/'+funcionarioId+'/folhaPontoMensal?Ano='+usingSplit[0]+'&Mes='+usingSplit[1]);
    }

    listarMeses(funcionarioId, dataInicial, dataFinal) : any {
        return this.appService.Get('funcionario/'+funcionarioId+'/registrosponto?DataInicial='+this.DataString(dataInicial)+'&DataFinal='+this.DataString(dataFinal));
    }

    listarSolicitacaoRegistroPonto(funcionarioId) : any {
        return this.appService.Get('funcionario/'+funcionarioId+'/solicitacoes');
    }

    funcionarioListarMesesDisponiveisRelatorio(funcionarioId) : any {
        return this.appService.Get('funcionario/'+funcionarioId+'/meses');
    }

    listarMesesDisponiveisRelatorio() : any {
        return this.appService.Get('funcionario/meses');
    }

    incluirPontoBancoHoras(funcionarioId, tempo, justificativa){
        return this.appService.PostSpot('bancoHoras/funcionarios/'+funcionarioId+'/lancamentos', {Tempo: tempo, Justificativa: justificativa});
    }

    listarAjustesBancoHoras(funcionarioId, data){
        const usingSplit = data.split('-');
        return this.appService.GetSpot('bancoHoras/funcionarios/'+funcionarioId+'/lancamentos?Ano='+usingSplit[0]+'&Mes='+usingSplit[1]);
    }
}