import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { AppService } from "app/App.service";

@Injectable()
export class FeriasFuncionarioService
{
    constructor(private appService: AppService) { }

    ListarFeriasFuncionario(funcionarioId)
    {
        return this.appService.Get('ferias/funcionario/' + funcionarioId);
    }

    SolicitarFerias(inicio:Date, fim:Date)
    {
        let corpo = {
            Inicio: inicio,
            Fim: fim
        };

        return this.appService.Post('ferias/solicitacao/', corpo);
    }

    ListarFerias()
    {
        return this.appService.Get('ferias/visualizacao');
    }
}