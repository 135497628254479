import { SpotNotificationComponent } from './../components/spot-notification/SpotNotification.component';
import { RegistroPontoService } from './registro-ponto.service';
import { Component, OnInit } from '@angular/core';

export class RegistroPonto { 
  login: string;
  matricula: number;
  latitude: number;
  longitude: number; 
}

@Component({
  selector: 'registro-ponto',
  templateUrl: './registro-ponto.component.html',
  styleUrls: ['./registro-ponto.component.css'],
})
export class RegistroPontoComponent implements OnInit {
    RegistroPonto = new RegistroPonto();

    botaoLiberado = true;

    constructor(private registroPontoService: RegistroPontoService,
        private notification: SpotNotificationComponent) { }

    ngOnInit() {
        this.Local();
    }

    Registrar() {
        this.botaoLiberado = false;

         this.registroPontoService.Registrar(this.RegistroPonto)
        .subscribe(data => {
            this.notification.showNotificationHorario("success", data.nome, data.horario);
            this.botaoLiberado = true;
        },
        err =>  {
            this.notification.showNotification("danger", err.error)
            this.botaoLiberado = true;
        });
    }

    Local() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => { 
                this.RegistroPonto.latitude = position.coords.latitude; 
                this.RegistroPonto.longitude = position.coords.longitude;
            });
        }
    }
}