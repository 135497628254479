import { environment } from './../../environments/environment';
import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./util.css', './main.css']
})
export class LoginComponent {
    login;
    senha;
    token;
    erro;

    private _apiUrlLogin: string = environment.apiEndpoint + 'login';

    loader = false;

    headers = new HttpHeaders()
        .set('Content-Type', 'application/json');

    constructor(private http: HttpClient, private router: Router) { }

    Entrar()
    {
        this.erro = '';
        this.loader = true;
        
        let corpo =  {
            login: this.login,
            senha: this.senha
        };

        (<any>(this.http.post(this._apiUrlLogin, JSON.stringify(corpo), {headers: this.headers})
            )).subscribe(data => {
                this.token = data.funcionario.token;
                
                localStorage.setItem('currentUser', JSON.stringify(this.token));
                localStorage.setItem("funcionarioNome", JSON.stringify(data.funcionario.nome))
                localStorage.setItem("funcionarioFilial", JSON.stringify(data.funcionario.filial))
                localStorage.setItem('funcionarioId', JSON.stringify(data.funcionario.funcionarioId));

                this.router.navigate(['/dashboard']);

                this.loader = false;
        },
        err =>  {
            this.loader = false;
            this.erro = err.error.notifications[0].mensagem;
        });
    }
}