import { Injectable } from '@angular/core';

declare var $: any;

@Injectable()
export class SpotNotificationComponent{
  
  constructor() {  }

  showNotification(type, erros = '') {

      let messagem = type === 'success' ? "Registro salvo com sucesso!" : this.Erros(erros);

      $.notify({
          icon: "notifications",
          message: messagem
      },{
          type: type,
          timer: 2000,
          placement: {
              from: 'top',
              align: 'right'
          },
          template: '<div data-notify="container" class="col-xl-3 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
            '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
            '<i class="material-icons" data-notify="icon">notifications</i> ' +
            '<span data-notify="title">{1}</span> ' +
            '<span data-notify="message">{2}</span>' +
            '<div class="progress" data-notify="progressbar">' +
              '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
            '</div>' +
            '<a href="{3}" target="{4}" data-notify="url"></a>' +
          '</div>'
      });
  }

  showNotificationHorario(mensagem, nome, horario) {
      let horarioPonto = new Date(horario);

      $.notify({
          icon: "notifications",
          message: mensagem,
      },{
          type: 'success',
          timer: 2000,
          placement: {
              from: 'top',
              align: 'right'
          },
          template: '<div data-notify="container" class="col-xl-3 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
            '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
            '<i class="material-icons" data-notify="icon">notifications</i> ' +
            '<span data-notify="title">{1}</span> ' +
            '<span data-notify="message">Registro ponto realizado!</span>' +
            '<span>Nome:' + nome + '</span>' +
            'Horário: ' + ("0" + horarioPonto.getHours()).slice(-2) + ':' + horarioPonto.getMinutes() +
            '<div class="progress" data-notify="progressbar">' +
              '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
            '</div>' +
            '<a href="{3}" target="{4}" data-notify="url"></a>' +
          '</div>'
      });
  }
  
  Erros(erros)
  {
    let errors: Array<string> = [];

    erros.notifications.forEach(element => {
        errors.push(element.mensagem);
    });

    return errors;
  }
}