import { SampleService } from './sample.service';
import { Interceptor } from './interceptor.module';
import { EscalaService } from './escala/escala.service';
import { RegistroPontoFuncionarioService } from './registro-ponto-funcionario/registro-ponto-funcionario.service';
import { RegistroPontoService } from './registro-ponto/registro-ponto.service';
import { RegistroPontoComponent } from './registro-ponto/resgistro-ponto.component';
import { FeriasFuncionarioService } from './ferias-funcionario/ferias-funcionario.service';
import {MatDialogModule} from '@angular/material';
import { CepService } from './shared/cep.service';
import { SetorService } from './components/setor/setor.service';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';

import { HttpClientModule } from '@angular/common/http';

import { FuncionarioService } from "app/funcionarios/funcionarios.service.component";
import { AppService } from "app/App.service";
import { AuthGuard } from "app/auth.guard";

import {MatSidenavModule} from '@angular/material/sidenav';
import { SpotNotificationComponent } from "app/components/spot-notification/SpotNotification.component";
import { FilialService } from "app/filiais/filial.service.component";

import { NgProgressModule } from '@ngx-progressbar/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import { BrowserModule } from '@angular/platform-browser';
import { AbonoService } from './Abono/abono.service';
import { GestaoHorariosService } from './gestao-horarios/gestao-horarios.service';
import { LgpdComponent } from './lgpd/lgpd.component';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    MatSidenavModule,
    MatDialogModule,
    NgProgressModule.forRoot(),
    BrowserModule,
    FullCalendarModule,
    Interceptor
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
    RegistroPontoComponent,
    LgpdComponent
  ],
  providers: [
    FuncionarioService,
    AppService,
    AuthGuard,
    SpotNotificationComponent,
    FilialService,
    SetorService,
    CepService,
    FeriasFuncionarioService,
    RegistroPontoFuncionarioService,
    RegistroPontoService,
    EscalaService,
    SampleService,
    AbonoService,
    GestaoHorariosService
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
