import { Injectable } from '@angular/core';
import { AppService } from "app/App.service";

@Injectable()
export class FilialService
{
    constructor(private appService: AppService) { }

    ListarFilial()
    {
        return this.appService.Get('filial');
    }

    ListarSetores(filialId)
    {
        return this.appService.Get('filial/'+filialId+'/setor');
    }

    ObterFilial(filialId)
    {
        return this.appService.Get('filial/'+filialId);
    }

    ObterFilialComposicao(filialId, composicao)
    {
        return this.appService.Get('filial/'+filialId + '?composicao=' + composicao);
    }

    IncluirFilial(corpo) : any
    {
        return this.appService.Post('filial', {filial: corpo});
    }

    AlterarFilial(corpo, filialId)
    {
        return this.appService.Put('filial/' + filialId, {filial: corpo}).subscribe();
    }

    inativarDispositivoSincronizacao(filialId, dispositivoId)
    {
        return this.appService
            .Put('filial/'+filialId + '/dispositivo/'+ dispositivoId + '/inativar', null)
            .subscribe();
    }

    gerarNovoCodigo(filialId) : any
    {
        return this.appService.Put('filialSincronizacao/filial/' + filialId + '/gerarCodigo', null);
    }

    IncluirSetor(filialId, corpo) : any
    {
        return this.appService.Post('filial/'+filialId+'/setor', {setor: corpo})
    }

    AlterarSetor(filialId, setorId, corpo) : any
    {
        return this.appService.Put('filial/'+filialId+'/setor/'+setorId, {setor: corpo})
    }

    AlterarFilialSincronizacao(filialId, filialSincronizacao) : any
    {
        return this.appService.Put('filialSincronizacao/filial/'+filialId, {filialSincronizacao})
    }

    AlterarSituacaoAtivo(setorId, ativo: boolean)
    {
        return this.appService.PutSituacaoAtivo('setor/'+setorId+'?ativo='+ativo);
    }

    DeletarSetor(setorId)
    {
        return this.appService.Delete('setor/'+setorId);
    }
}