import { Cep } from './cep';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class CepService {
    
    constructor(private _http: HttpClient) { }

    GetCep(cep: string) : any {
        return this._http.get(`https://viacep.com.br/ws/${cep}/json`)
    }
}